import * as React from "react"
import { Edit, FormDataConsumer, SimpleForm } from "react-admin"

import { Integration, IntegrationType } from "@cyphereye/schema/src/integration"

import {
  TextFieldWithCopyRow,
  TextInputRow,
} from "../../components/FormRowThreeCol"

import useStyles from "./styles"
import { transform, validate } from "./validation"
import IntegrationEditSaml from "./IntegrationEditSaml"
import IntegrationEditLock from "./IntegrationEditLock"

const IntegrationEditTitle = ({ record }: { record?: Integration }) => {
  return <span>Edit {record?.name}</span>
}

const IntegrationEdit = (props: any) => {
  const classes = useStyles()

  return (
    <Edit {...props} title={<IntegrationEditTitle />} transform={transform}>
      <SimpleForm warnWhenUnsavedChanges validate={validate}>
        <TextFieldWithCopyRow
          source="id"
          label="ID"
          description="ID of this integration"
        />
        <TextInputRow
          source="name"
          label="Name"
          description="Name will appear on sign in page and email notifications."
        />
        <FormDataConsumer>
          {({ formData, ...rest }: { formData: any; rest: any }) =>
            formData.type === IntegrationType.Saml ? (
              <IntegrationEditSaml {...rest} />
            ) : (
              <IntegrationEditLock {...rest} />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export default IntegrationEdit
