import * as React from "react"

import { TextInput } from "react-admin"

import { TextInputRow } from "../../components/FormRowThreeCol"

export default function IntegrationCreateLock() {
  return (
    <>
      <TextInput
        source="meta.unlocked"
        initialValue={false}
        style={{ display: "none" }}
      />
      <TextInputRow
        label="Unlock Duration"
        description="Number of seconds the lock should remain unlocked for."
        source="meta.unlockDurationSeconds"
      />
    </>
  )
}
