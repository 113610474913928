import * as React from "react"

import { StaticTextRow, TextInputRow } from "../../components/FormRowThreeCol"

export default function IntegrationEditLock(props: any) {
  return (
    <>
      <StaticTextRow
        label="Eye ID"
        description="ID for the Eye for the Lock"
        source="meta.eyeId"
      />
      <TextInputRow
        label="Unlock Duration"
        description="Number of seconds the lock should remain unlocked for."
        source="meta.unlockDurationSeconds"
      />
    </>
  )
}
