import { Eye, EyeStatus, EyeType } from "@cyphereye/schema/src/eye"

import eyeData from "../../api/eyeData"

export { EyeStatus }

const pollInterval = 2_000

export default async function waitForScan(eyeId: number) {
  return new Promise<EyeStatus>((resolve, reject) => {
    const check = async () => {
      try {
        const eye: Eye = await eyeData(eyeId)
        switch (eye.status) {
          case EyeStatus.DeviceNotRegistered:
            resolve(EyeStatus.DeviceNotRegistered)
            break
          case EyeStatus.Scanned:
          case EyeStatus.Failed:
            await processEye(eye)
            resolve(eye.status)
            break
          default:
            setTimeout(check, pollInterval)
            break
        }
      } catch (error) {
        reject(error)
      }
    }
    setTimeout(check, pollInterval)
  })
}
async function processEye(eye: Eye) {
  switch (eye.type) {
    case EyeType.SignIn:
      window.location.href = eye.meta.redirect!
      break
    default:
      break
  }
}
