import get from "lodash/get"
import React from "react"
import { TextField } from "react-admin"

import ClipboardCopyButton from "./ClipboardCopyButton"

export default function TextFieldWithCopy(props: any) {
  const { className, record, source, style } = props
  const value = get(record, source)

  return (
    <div className={className} style={style}>
      <TextField {...props} />
      <ClipboardCopyButton content={value} />
    </div>
  )
}
