import * as React from "react"
import {
  Create,
  FormDataConsumer,
  RadioButtonGroupInput,
  SimpleForm,
  Toolbar,
} from "react-admin"

import { IntegrationType } from "@cyphereye/schema/src/integration"

import useStyles from "./styles"
import { getInitialValues, transform, validate } from "./validation"

import { TextInputRow } from "../../components/FormRowThreeCol"
import ActiveCheckbox from "./ActiveCheckbox"
import IntegrationCreateSaml from "./IntegrationCreateSaml"
import IntegrationCreateLock from "./IntegrationCreateLock"

const IntegrationCreate = (props: any) => {
  const classes = useStyles()

  const types = Object.entries(IntegrationType)
  const typeChoices = types.map(([name, id]) => ({ id, name }))

  // alwaysEnableSaveButton as form can be pre-populated with a record when
  // importing from XML metadata - see `ImportMetadataButton`.
  return (
    <Create {...props} title="New Integration" transform={transform}>
      <SimpleForm
        warnWhenUnsavedChanges
        initialValues={getInitialValues()}
        toolbar={<Toolbar alwaysEnableSaveButton />}
        validate={validate}
      >
        <RadioButtonGroupInput source="type" choices={typeChoices} />
        <TextInputRow
          label="Name"
          description="Name will appear on sign in page and email notifications."
          source="name"
        />
        <ActiveCheckbox />
        <FormDataConsumer>
          {({ formData }: { formData: any }) =>
            formData.type === IntegrationType.Saml ? (
              <IntegrationCreateSaml />
            ) : (
              <IntegrationCreateLock />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default IntegrationCreate
