import * as React from "react"
import { TextInput } from "react-admin"

import useStyles from "./styles"

import { StaticTextRow, TextInputRow } from "../../components/FormRowThreeCol"

export default function IntegrationCreateSaml() {
  const classes = useStyles()

  return (
    <>
      <TextInputRow
        label="SAML SP Entity ID"
        description="Service Provider (SP) unique identifier."
        source="meta.entityId"
      />
      <TextInputRow
        label="Assertion Customer Service"
        source="meta.assertionConsumerService"
        description="The SP sign-in URL. Endpoint provided by the SP where SAML responses are posted."
      />
      <TextInputRow
        label="SP Metadata Document"
        description="XML file for the Service Provider configuration."
        source="meta.metadataUrl"
      />
      <StaticTextRow
        label="Service Provider Certificate"
        description="Certificate in PEM format"
      />
      <TextInput
        label="add the certificate"
        source="meta.certificate"
        className={classes.preTextArea}
        fullWidth
        multiline
      />
    </>
  )
}
