import * as React from "react"
import { TextInput } from "react-admin"

import { TextInputRow } from "../../components/FormRowThreeCol"

import DownloadMetadataButton from "./DownloadMetadataButton"
import ActiveCheckbox from "./ActiveCheckbox"
import useStyles from "./styles"
import IntegrationAuthUrl from "./IntegrationAuthUrl"
import CertificateShow from "./CertificateShow"

export default function IntegrationEditSaml(props: any) {
  const classes = useStyles()

  return (
    <>
      <DownloadMetadataButton />
      <IntegrationAuthUrl />
      <ActiveCheckbox />
      <TextInputRow
        source="meta.policy.trustScoreThreshold"
        label="Trust Score Threshold"
        description="Minimum trust score required to sign in"
      />
      <TextInputRow
        source="meta.entityId"
        label="SAML SP Entity ID"
        description="Service Provider (SP) unique identifier"
      />
      <TextInputRow
        source="meta.assertionConsumerService"
        label="Assertion Consumer Service"
        description="The SP sign-in URL. Endpoint provided by the SP where SAML responses are posted."
      />
      <TextInputRow
        source="meta.metadataUrl"
        label="SP Metadata Document"
        description="XML file for the Service Provider configuration"
      />

      <CertificateShow {...props} />
      <TextInput
        source="meta.certificate"
        label=""
        className={classes.preTextArea}
        fullWidth
        multiline
      />
    </>
  )
}
